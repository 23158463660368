import React from 'react';
import classes from './index.module.css';
import { useMediaQuery } from '@mantine/hooks';

const Sideitem = ({ name, icon, isActive, onClick }) => {
  const isMobile = useMediaQuery('(max-width: 450px)');
  return name !== 'Guia do jogo' ? (
    <div
      className={!isActive ? classes.root : classes.active}
      onClick={onClick}
    >
      {icon}
      {!isMobile && <p style={{ lineHeight: 0 }}>{name}</p>}
    </div>
  ) : (
    <a
      href="https://wiki.mt2dominium.com/"
      target="_blank"
      className={!isActive ? classes.root : classes.active}
      onClick={onClick}
      style={{ textDecoration: 'none' }}
    >
      {icon}
      {!isMobile && <p style={{ lineHeight: 0 }}>{name}</p>}
    </a>
  );
};

export default Sideitem;
