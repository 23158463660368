import React, { useContext, useEffect, useState } from 'react';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import PageTitle from '../../Components/PageTitle';
import globalClasses from '../../App.module.css';
import Button from '../../Components/Button';
import AppTable from '../../Components/AppTable';
import { Table } from '@mantine/core';
import { AppContext } from '../../Context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Balance from '../../Components/Balance';

const Dashboard = () => {
  const { isAuth, handleLogout, profile, getCharClass } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [elements, setElements] = useState([]);

  useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    }
  }, [isAuth]);

  useEffect(() => {
    if (profile) {
      if (profile.players) {
        let chars = [];
        profile.players.map((char) => {
          chars.push({
            level: char.level,
            name: char.name,
            classe: getCharClass(char.job),
            playtime: char.playtime,
          });
        });
        setElements(chars);
      }
    }
  }, [profile]);

  const heads = ['Classe', 'Nome', 'Nível', 'Minutos logado'];

  const rows = elements.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.classe}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.level}</Table.Td>
      <Table.Td>{element.playtime}</Table.Td>
    </Table.Tr>
  ));

  const logout = () => {
    handleLogout();
  };

  return (
    <div style={{ width: '100%' }}>
      <PageTitle>Minha conta</PageTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Balance />
      </div>

      <PageWrapperFluid>
        <div className={globalClasses.rowBox}>
          <div className={globalClasses.rowItem}>
            <AppTable heads={heads} rows={rows} />
          </div>
          <div
            className={globalClasses.rowItem}
            style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Link style={{ textDecoration: 'none' }} to="/cash">
                <span>Comprar cash</span>
              </Link>
              {/* <div>
                <span>Trocar senha</span>
              </div>
              <div>
                <span>Trocar email</span>
              </div>
              <div>
                <span>Senha do armazém</span>
              </div>
              <div>
                <span>Senha de apagar personagem</span>
              </div>
               */}
            </div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
              }}
            >
              <Button onClick={logout}>Logout</Button>
            </div>
          </div>
        </div>
      </PageWrapperFluid>
    </div>
  );
};

export default Dashboard;
