import classes from './index.module.css';
import Iso from '../../Assets/iso.png';
import Button from '../../Components/Button/index';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../Context/AppContext';
import { useMediaQuery } from '@mantine/hooks';
import TokenMestre from '../../Assets/token.png';
import { Link, useNavigate } from 'react-router-dom';

export function Header() {
  const { isAuth, bank, totalTokenAmount, cashout, isLoading } =
    useContext(AppContext);
  const isSmall = useMediaQuery('(min-width: 1080px)');
  const isMobile = useMediaQuery('(min-width: 700px)');
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDifference = cashout - now;

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setCountdown(`${days}D ${hours}H ${minutes}M ${seconds}S`);

      if (timeDifference < 0) {
        clearInterval(interval);
        setCountdown('DIA DE PAGAMENTO');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cashout]);

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <Link to="/">
          <img src={Iso} alt="Metin2 DOMINIUM" />
        </Link>
      </div>
      {/* {isSmall && countdown.length > 0 && (
        <div className={classes.info}>
          <div className={classes.infoLabel}>
            <p>PROXIMO PAGAMENTO:</p>
            <span>{countdown}</span>
          </div>
          <div className={classes.infoLabel}>
            <img src={TokenMestre} alt="Token do Mestre" />
            <span>{`R$ ${(totalTokenAmount !== 0
              ? bank / totalTokenAmount
              : bank
            ).toFixed(2)}`}</span>
          </div>
        </div>
      )} */}
      {isAuth ? (
        <div className={classes.cta}>
          {isMobile && (
            <Button onClick={() => navigate('/conta')}>MINHA CONTA</Button>
          )}
          <Button onClick={() => navigate('/cash')} highlight>
            COMPRAR CASH
          </Button>
        </div>
      ) : (
        <div className={classes.cta}>
          {isMobile && (
            <Button onClick={() => navigate('/login')}>LOGIN</Button>
          )}
          <Button highlight onClick={() => navigate('/login')}>
            COMPRAR CASH
          </Button>
        </div>
      )}
    </div>
  );
}
