import React from 'react';

const RankingSvg = () => {
  return (
    <svg
      width="28"
      height="54"
      viewBox="0 0 28 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666504 0.333328H27.3332V21.2667C27.3332 22.2889 27.1109 23.2 26.6665 24C26.2221 24.8 25.5998 25.4444 24.7998 25.9333L15.3332 31.5333L17.1998 37.6667H27.3332L19.0665 43.5333L22.2665 53.6667L13.9998 47.4L5.73317 53.6667L8.93317 43.5333L0.666504 37.6667H10.7998L12.6665 31.5333L3.19984 25.9333C2.39984 25.4444 1.77761 24.8 1.33317 24C0.888726 23.2 0.666504 22.2889 0.666504 21.2667V0.333328ZM5.99984 5.66666V21.2667L11.3332 24.4667V5.66666H5.99984ZM21.9998 5.66666H16.6665V24.4667L21.9998 21.2667V5.66666Z"
        fill="#F3F2F2"
      />
    </svg>
  );
};

export default RankingSvg;
