import React from 'react';
import QRCode from 'react-qr-code';

const QRCodeComponent = ({ base64Image }) => {
  return (
    <div>
      <QRCode value={base64Image} />
    </div>
  );
};

export default QRCodeComponent;
