import { createTheme, MantineProvider } from '@mantine/core';
import { AppStorage } from './Context/AppContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import classes from './App.module.css';
import { Header } from './Components/Header/index';
import { Sidebar } from './Components/Sidebar/index';
import Contest from './Pages/Contest';
import Download from './Pages/Download';
import Ranking from './Pages/Ranking';
import Dashboard from './Pages/Dashboard';
import LoginPage from './Pages/Login';
import Buy from './Pages/Buy';
import PayInfo from './Pages/PayInfo';
import Daily from './Pages/Daily';
import ResetPassword from './Pages/ResetPassword';
import Register from './Pages/Register';

const theme = createTheme({
  white: '#FAFAFA',
  black: '#181717',
  colors: {
    l: [
      '#FAFAFA',
      '#F8F7F7',
      '#F3F2F2',
      '#ECE9E9',
      '#E7E0DE',
      '#D6C6C2',
      '#CCACA4',
      '#C99282',
      '#D16847',
    ],
    d: [
      '#CE542C',
      '#A15236',
      '#814C37',
      '#6A4839',
      '#5C473D',
      '#51443E',
      '#3E3632',
      '#282624',
      '#181717',
    ],
    metinRed: ['#F4445C'],
    metinBlue: ['#4456F4'],
    metinYellow: ['#F1F444'],
  },
  spacing: {
    xs: 10,
    sm: 20,
    md: 40,
    lg: 80,
    xl: 120,
  },
  shadows: {
    xs: '0px 4px 8px rgba(13, 11, 8, 0.25)',
    sm: '0px 2px 2px rgba(13, 11, 8, 0.25)',
    md: '0px 4px 2px rgba(13, 11, 8, 0.25)',
    lg: '0px 8px 2px rgba(13, 11, 8, 0.25)',
    xl: '0px 16px 4px rgba(13, 11, 8, 0.25)',
  },
  defaultGradient: {
    deg: 180,
    from: '33322E',
    to: '171511',
  },
  defaultRadius: {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
  },
  headings: {
    fontFamily: 'Raleway, sans-serif',
    lineHeight: 1,
    sizes: {
      h1: { fontSize: 36, fontWeight: 800, lineHeight: 1 },
      h2: { fontSize: 28, fontWeight: 500, lineHeight: 1 },
      p: { fontSize: 22, fontWeight: 300, lineHeight: 1 },
    },
  },
  primaryColor: 'd',
  fontFamily: 'Bebas Neue, sans-serif',
  other: {
    mainBorder: '#E6A920',
    disableBorder: '#3D3C38',
    gameFrame: '#1D1F1E',
  },
});

function App() {
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <BrowserRouter>
        <AppStorage>
          <div className={classes.root}>
            <Header />
            <div className={classes.main}>
              <Sidebar />
              <div className={classes.content}>
                <Routes>
                  <Route path="/" element={<Contest />} />
                  <Route path="/baixar" element={<Download />} />
                  <Route path="/cadastrar" element={<Register />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/conta" element={<Dashboard />} />
                  <Route path="/cash" element={<Buy />} />
                  {/* <Route path="/diaria" element={<Daily />} /> */}
                  {/* <Route path="/trocar-senha" element={<ResetPassword />} /> */}
                  <Route path="/ranking" element={<Ranking />} />
                  {/* <Route path="/info" element={<PayInfo />} /> */}
                </Routes>
              </div>
            </div>
          </div>
        </AppStorage>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
