import React, { useContext, useState } from 'react';
import { AppContext } from '../../Context/AppContext';
import classes from './index.module.css';
import Cristal from '../../Assets/cristal.png';
import MasterToken from '../../Assets/token.png';
import CashCoin from '../../Assets/cash.png';
import Visibility from '../../Assets/Svgs/visibility.svg';

const Balance = ({ onlyCash }) => {
  const { cristalAmount, tokenAmount, cashAmount, getEmpire, profile } =
    useContext(AppContext);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={classes.container}>
      {/* {!onlyCash && (
        <div className={classes.item}>
          <img src={Cristal} alt="Cristal" />
          <p>{isVisible ? cristalAmount : '-'}</p>
        </div>
      )}
      {!onlyCash && (
        <div className={classes.item}>
          <img src={MasterToken} alt="Token do Mestre" />
          <p>{isVisible ? tokenAmount : '-'}</p>
        </div>
      )} */}
      <div className={classes.item}>
        <img src={CashCoin} alt="Cash" />
        <p>{onlyCash ? cashAmount : isVisible ? cashAmount : '-'}</p>
      </div>
      {!onlyCash && (
        <div className={classes.item}>
          <img
            className={classes.icon}
            onClick={toggleVisible}
            src={Visibility}
            alt="Mostrar/Ocultar"
          />
        </div>
      )}
    </div>
  );
};

export default Balance;
