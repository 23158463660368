import React from 'react';

const RankingImage = ({ img }) => {
  return (
    <div
      style={{
        height: 120,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <img
        src={img}
        style={{
          height: 'auto',
          objectFit: 'none',
          width: '100%',
          objectPosition: 'top',
        }}
      />
    </div>
  );
};

export default RankingImage;
