import React, { useContext, useEffect } from 'react';
import PageWrapper from '../../Components/PageWrapper';
import PageTitle from '../../Components/PageTitle';
import Login from '../../Components/Forms/Login';
import { AppContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { isAuth } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/conta');
    }
  }, [isAuth]);

  return (
    <div>
      <PageTitle>Login</PageTitle>
      <div
        style={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PageWrapper>
          <h2>Acesse sua conta</h2>
          <Login />
        </PageWrapper>
      </div>
    </div>
  );
};

export default LoginPage;
