import React from 'react';

const ContestSvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 21.3333C25.4667 21.3333 26.7222 20.8111 27.7667 19.7667C28.8111 18.7222 29.3333 17.4667 29.3333 16C29.3333 14.5333 28.8111 13.2778 27.7667 12.2333C26.7222 11.1889 25.4667 10.6667 24 10.6667C22.5333 10.6667 21.2778 11.1889 20.2333 12.2333C19.1889 13.2778 18.6667 14.5333 18.6667 16C18.6667 17.4667 19.1889 18.7222 20.2333 19.7667C21.2778 20.8111 22.5333 21.3333 24 21.3333ZM10.6667 48V42.6667H21.3333V34.4C19.1556 33.9111 17.2111 32.9889 15.5 31.6333C13.7889 30.2778 12.5333 28.5778 11.7333 26.5333C8.4 26.1333 5.61111 24.6778 3.36667 22.1667C1.12222 19.6556 0 16.7111 0 13.3333V10.6667C0 9.2 0.522222 7.94444 1.56667 6.9C2.61111 5.85556 3.86667 5.33333 5.33333 5.33333H10.6667V0H37.3333V5.33333H42.6667C44.1333 5.33333 45.3889 5.85556 46.4333 6.9C47.4778 7.94444 48 9.2 48 10.6667V13.3333C48 16.7111 46.8778 19.6556 44.6333 22.1667C42.3889 24.6778 39.6 26.1333 36.2667 26.5333C35.4667 28.5778 34.2111 30.2778 32.5 31.6333C30.7889 32.9889 28.8444 33.9111 26.6667 34.4V42.6667H37.3333V48H10.6667ZM10.6667 20.8V10.6667H5.33333V13.3333C5.33333 15.0222 5.82222 16.5444 6.8 17.9C7.77778 19.2556 9.06667 20.2222 10.6667 20.8ZM24 29.3333C26.2222 29.3333 28.1111 28.5556 29.6667 27C31.2222 25.4444 32 23.5556 32 21.3333V5.33333H16V21.3333C16 23.5556 16.7778 25.4444 18.3333 27C19.8889 28.5556 21.7778 29.3333 24 29.3333ZM37.3333 20.8C38.9333 20.2222 40.2222 19.2556 41.2 17.9C42.1778 16.5444 42.6667 15.0222 42.6667 13.3333V10.6667H37.3333V20.8Z"
        fill="#F3F2F2"
      />
    </svg>
  );
};

export default ContestSvg;
