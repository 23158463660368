import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import '@mantine/core/styles.css';
import App from './App';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

if (container.hasChildNodes()) {
  root.hydrate(<App />);
} else {
  root.render(<App />);
}
