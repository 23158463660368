import React, { useEffect } from 'react';
import axios from 'axios';
import { LoadingOverlay, Modal } from '@mantine/core';
import ShuraM from '../Assets/shura_m.png';
import ShuraF from '../Assets/shura_f.png';
import GuerreiroM from '../Assets/guerreiro_m.png';
import GuerreiroF from '../Assets/guerreiro_f.png';
import NinjaF from '../Assets/ninja_f.png';
import NinjaM from '../Assets/ninja_m.png';
import ShamanF from '../Assets/shaman_f.png';
import ShamanM from '../Assets/shaman_m.png';
import Red from '../Assets/red.png';
import Blue from '../Assets/blue.png';
import Yellow from '../Assets/yellow.png';
import PageWrapperFluid from '../Components/PageWrapperFluid';
import PageTitle from '../Components/PageTitle';
import { useNavigate } from 'react-router-dom';

export const AppContext = React.createContext({});

export const AppStorage = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAuth, setIsAuth] = React.useState(false);
  const [profile, setProfile] = React.useState(undefined);
  const [error, setError] = React.useState({ message: '' });
  const [cristalAmount, setCristalAmount] = React.useState(0);
  const [tokenAmount, setTokenAmount] = React.useState(0);
  const [cashAmount, setCashAmount] = React.useState(0);
  const [isBuying, setIsBuying] = React.useState(false);
  const [buyStatus, setBuyStatus] = React.useState('');
  const [currentBuyData, setCurrentBuyData] = React.useState();

  const [bank, setBank] = React.useState(0);
  const [premiumPlayers, setPremiumPlayers] = React.useState(0);
  const [rankingPlayers, setRankingPlayers] = React.useState([]);
  const [rankingQuests, setRankingQuests] = React.useState([]);
  const [rankingGuilds, setRankingGuilds] = React.useState([]);
  const [totalTokenAmount, setTotalTokenAmount] = React.useState(0);
  const [cashout, setCashout] = React.useState(new Date().getTime());
  const [statistics, setStatistics] = React.useState();

  const api = axios.create({
    baseURL: 'https://mt2dominium.com:8080/api/',
  });

  const navigate = useNavigate();

  const closeErrorModal = () => {
    setError({ message: '' });
  };

  const handleLogin = async (loginData) => {
    setIsLoading(true);
    try {
      const response = await api.post('login', loginData);
      const { token, success } = response.data;
      if (success) {
        localStorage.setItem('token', token);
        setIsAuth(true);
        setProfile(response.data.user);
      } else {
        setError({
          message: 'Credenciais não encontradas.',
        });
      }
    } catch (error) {
      setError({
        message: 'Credenciais não encontradas.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignup = async (registerData) => {
    setIsLoading(true);
    try {
      const response = await api.post('register', registerData);
      const { success, token, message } = response.data;
      if (success) {
        localStorage.setItem('token', token);
        setIsAuth(true);
        navigate('/conta');
      } else {
        setError({
          message: message,
        });
      }
    } catch (error) {
      setError({
        message: 'Falha no cadastro, tente novamente',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Token de autenticação não encontrado.');
        setIsAuth(false);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post('logout', {}, config);
      const { success, message } = response.data;

      if (success) {
        localStorage.removeItem('token');
        setIsAuth(false);
      } else {
        console.error('Erro ao realizar logout:', message);
      }
    } catch (error) {
      console.error('Falha ao realizar logout:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError({
          message: 'Token não encontrado.',
        });
        setIsAuth(false);
        return;
      }

      const response = await api.get('get-profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { success, user } = response.data;
      if (success) {
        let totalTokens = 0;
        let totalCristal = 0;
        user.players.map((char) => {
          return char.items.map((item) => {
            if (item.vnum === 80008) {
              totalTokens += item.count;
            }
            if (item.vnum === 30203) {
              totalCristal += item.count;
            }
          });
        });
        setProfile(user);
        setTokenAmount(totalTokens);
        setCristalAmount(totalCristal);
        setCashAmount(user.coins);
      } else {
        setError({
          message: 'Erro ao buscar perfil do usuário.',
        });
        localStorage.removeItem('token');
        setIsAuth(false);
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar perfil do usuário.',
      });
      localStorage.removeItem('token');
      setIsAuth(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAppData = async () => {
    setIsLoading(true);
    try {
      const tokenCall = await api.get('total-tokens');
      const { success, items } = tokenCall.data;
      if (success) {
        let totalTokens = 0;
        items.map((token) => {
          totalTokens += Number(token.count);
        });
        setTotalTokenAmount(totalTokens);
      } else {
        setError({
          message: 'Erro ao buscar dados dos tokens.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados dos tokens.',
      });
    }

    try {
      const premiumCall = await api.get('premium-players');
      const { success, players } = premiumCall.data;
      if (success) {
        let totalCharPremium = players.length;
        setPremiumPlayers(totalCharPremium);
      } else {
        setError({
          message: 'Erro ao buscar dados dos premiums.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados dos premiums.',
      });
    }

    try {
      const fundsCall = await api.get('total-funds');
      const { success, funds } = fundsCall.data;
      if (success) {
        let totalBank = 0;
        funds.map((fund) => {
          totalBank += Number(fund.fund);
        });
        setBank(totalBank);
      } else {
        setError({
          message: 'Erro ao buscar dados de pagamento.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados de pagamento.',
      });
    }

    try {
      const seasonCall = await api.get('seasons');
      const { success, seasons } = seasonCall.data;
      if (success) {
        const now = new Date().getTime();
        const currentSeason = seasons.find(
          (seasons) =>
            new Date(seasons.start_date).getTime() <= now &&
            new Date(seasons.end_date).getTime() >= now,
        );
        if (currentSeason) {
          const end_date_temporada_atual = new Date(
            currentSeason.end_date,
          ).getTime();
          setCashout(end_date_temporada_atual);
        } else {
          // Define um valor padrão caso não haja temporada atual
          setCashout(new Date().getTime());
        }
      } else {
        setError({
          message: 'Erro ao buscar dados da temporada.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados da temporada.',
      });
    }

    try {
      const allPlayersResponse = await api.get('all-players');
      const allAccountsResponse = await api.get('all-accounts');
      const allGuildsResponse = await api.get('all-guilds');

      const { success: playersSuccess, players } = allPlayersResponse.data;
      const { success: accountsSuccess, accounts } = allAccountsResponse.data;
      const { success: guildsSuccess, guilds } = allGuildsResponse.data;

      if (playersSuccess && accountsSuccess && guildsSuccess) {
        const isLoggedLast24Hours = (player) => {
          const lastPlayTimestamp = new Date(player.last_play).getTime();
          const twentyFourHoursAgo = Date.now() - 32 * 60 * 60 * 1000;
          return lastPlayTimestamp >= twentyFourHoursAgo;
        };

        const isLoggedNow = (player) => {
          const lastPlayTimestamp = new Date(player.last_play).getTime();
          const oneMinuteAgo = Date.now() - 10 * 60 * 1000;
          return lastPlayTimestamp >= oneMinuteAgo;
        };

        let totalAccounts = accounts.length;
        let totalGuilds = guilds.length;
        let totalPlayers = players.length;
        let lastPlayers = players.filter(isLoggedLast24Hours).length;
        let onlinePlayers = players.filter(isLoggedNow).length;

        let body = {
          accounts: totalAccounts,
          guilds: totalGuilds,
          players: totalPlayers,
          lastPlayers: lastPlayers,
          online: onlinePlayers,
        };

        setStatistics(body);
      } else {
        setError({
          message: 'Erro ao buscar dados de estatisticas.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados de estatisticas.',
      });
    }

    try {
      const questCall = await api.get('top-quests');
      const { success, quests } = questCall.data;
      if (success) {
        let reps = quests
          .sort((a, b) => b.lValue - a.lValue)
          .filter((quest) => quest.player !== 'DOMINIUM')
          .filter((quest) => quest.szName === 'reputation_system');
        setRankingQuests(reps);
      } else {
        setError({
          message: 'Erro ao buscar dados do ranking.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados do ranking.',
      });
    }

    try {
      const playerCall = await api.get('top-players');
      const { success, players } = playerCall.data;
      if (success) {
        let rankingPlayers = players.filter(
          (player) => player.name !== 'DOMINIUM',
        );
        setRankingPlayers(rankingPlayers);
      } else {
        setError({
          message: 'Erro ao buscar dados do ranking.',
        });
      }
    } catch (error) {
      setError({
        message: 'Erro ao buscar dados do ranking.',
      });
    }

    setIsLoading(false);
  };

  const getCharClass = (job) => {
    let response;
    switch (job) {
      case 0:
        response = <img src={GuerreiroM} alt="Guerreiro" />;
        break;
      case 1:
        response = <img src={NinjaF} alt="Ninja" />;
        break;
      case 2:
        response = <img src={ShuraM} alt="Shura" />;
        break;
      case 3:
        response = <img src={ShamanF} alt="Shaman" />;
        break;
      case 4:
        response = <img src={GuerreiroF} alt="Guerreira" />;
        break;
      case 5:
        response = <img src={NinjaM} alt="Ninja" />;
        break;
      case 6:
        response = <img src={ShuraF} alt="Shura" />;
        break;
      case 7:
        response = <img src={ShamanM} alt="Shaman" />;
        break;
      default:
        <img src={ShuraM} alt="Shura" />;
        break;
    }
    return response;
  };

  const getEmpire = (index) => {
    let response;
    switch (index) {
      case 1:
        response = <img src={Red} style={{ height: 25 }} alt="Shinsoo/Red" />;
        break;
      case 3:
        response = <img src={Blue} style={{ height: 25 }} alt="Jinno/Blue" />;
        break;
      case 2:
        response = (
          <img src={Yellow} style={{ height: 25 }} alt="Chunjo/Yellow" />
        );
        break;
      default:
        <img src={Red} alt="Shinsoo/Red" />;
        break;
    }
    return response;
  };

  const handlePayment = async (value) => {
    setIsLoading(true);
    let { email, cn_key, login } = profile;
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + 31 * 60000);
    const formattedExpirationDate = expirationDate.toISOString();

    const buyData = {
      transaction_amount: value,
      description: 'CASH-DOMINIUM',
      payment_method_id: 'pix',
      payer: {
        email: email === '' ? 'solaktuto@gmail.com' : email,
        identification: {
          type: Number(cn_key).length >= 14 ? 'CPF' : '',
          number: Number(cn_key).length >= 14 ? Number(cn_key) : 0,
        },
      },
      date_of_expiration: formattedExpirationDate,
    };
    console.log(buyData);
    try {
      let idempotency = `${login}-${formattedExpirationDate}`;
      const response = await api.post('create-payment', buyData, {
        headers: {
          'X-Idempotency-Key': idempotency,
        },
      });
      setIsLoading(false);
      const { id } = response.data;

      if (id) {
        setIsBuying(true);
        setCurrentBuyData(response.data);
        setBuyStatus('waiting');

        // Inicia o loop de verificação do status do pagamento a cada 60 segundos ou até 30m máximo
        const intervalTime = 20000;
        const maxIntervalCount = 30;
        let intervalCount = 0;

        const intervalId = setInterval(async () => {
          if (intervalCount >= maxIntervalCount) {
            clearInterval(intervalId);
            setBuyStatus('expired');
            return;
          }

          const checkResponse = await api.get(`check-payment/${id}`);
          const { status } = checkResponse.data;

          console.log(status);
          if (status === 'approved') {
            clearInterval(intervalId);
            setBuyStatus('paid');
            setIsBuying(false);
            handleSuccessBuy(value);
          }
          intervalCount++;
        }, intervalTime);
      } else {
        setError({
          message: 'Erro ao registrar o pagamento.',
        });
        setBuyStatus('');
        setIsLoading(false);
        setIsBuying(false);
        setCurrentBuyData(undefined);
      }
    } catch (error) {
      setError({
        message: 'Erro ao registrar o pagamento.',
      });
      setBuyStatus('');
      setIsLoading(false);
      setIsBuying(false);
      setCurrentBuyData(undefined);
    }
  };

  const handleSuccessBuy = async (amount) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      let funds = (amount * 20) / 100;
      let body = {
        amount,
        fund: funds,
        login: profile.login,
      };
      const response = await api.post('register-fund', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { success } = response.data;
      if (success) {
        doCashing(amount * 100);
      } else {
        setError({
          message: 'Falha em adicionar o saldo no jogo.',
        });
      }
    } catch (error) {
      setError({
        message: 'Falha em adicionar o saldo no jogo.',
      });
    }
  };

  const doCashing = async (amount) => {
    try {
      const token = localStorage.getItem('token');
      let body = {
        id: profile.id,
        coins: amount,
      };
      console.log(body);
      const response = await api.post('add-cash', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { success, edit } = response.data;
      if (success) {
        console.log('cash adicionado a conta');
      } else {
        setError({
          message: 'Falha ao adicionar o cash na conta.',
        });
      }
    } catch (error) {
      setError({
        message: 'Falha ao adicionar o cash na conta.',
      });
    } finally {
      fetchProfile();
      fetchAppData();
    }
  };

  const handleAllow = async (allowData) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError({
          message: 'Token não encontrado.',
        });
        setIsAuth(false);
        return;
      }
      const response = await api.post('allow-cash', allowData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { success, edit } = response.data;
      if (success) {
        console.log(edit);
      } else {
        setError({
          message: 'Falha na validação da conta.',
        });
      }
    } catch (error) {
      setError({
        message: 'Falha na validação da conta.',
      });
    } finally {
      fetchProfile();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    fetchAppData();
  }, []);

  useEffect(() => {
    if (isAuth) {
      fetchProfile();
    }
  }, [isAuth]);

  return (
    <AppContext.Provider
      value={{
        isAuth,
        isLoading,
        handleLogin,
        handleLogout,
        handleSignup,
        profile,
        getCharClass,
        getEmpire,
        cristalAmount,
        tokenAmount,
        cashAmount,
        totalTokenAmount,
        bank,
        cashout,
        premiumPlayers,
        rankingPlayers,
        rankingGuilds,
        handleAllow,
        handlePayment,
        isBuying,
        buyStatus,
        currentBuyData,
        rankingQuests,
        statistics,
      }}
    >
      {/* <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      /> */}
      <Modal
        opened={error.message !== ''}
        onClose={closeErrorModal}
        centered
        withCloseButton={false}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: 40 }}>
          <span>{error.message}</span>
        </div>
      </Modal>

      {children}
    </AppContext.Provider>
  );
};
