import React, { useContext, useEffect, useState } from 'react';
import classes from './index.module.css';
import PageTitle from '../../Components/PageTitle';
import Balance from '../../Components/Balance';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import Button from '../../Components/Button';
import LabelFragment from '../../Components/LabelFragment';
import { NumberInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { AppContext } from '../../Context/AppContext';
import { initMercadoPago } from '@mercadopago/sdk-react';
import QRCodeComponent from '../../Components/QRCodeComponent';
import { Link, useNavigate } from 'react-router-dom';
import globalClasses from '../../App.module.css';

initMercadoPago('TEST-f3417a4d-130d-4dd5-a3d0-d9ffd8cb7067', {
  locale: 'pt-BR',
});

const Buy = () => {
  const {
    isAuth,
    profile,
    isBuying,
    handleAllow,
    handlePayment,
    buyStatus,
    currentBuyData,
  } = useContext(AppContext);

  const [cashAmount, setCashAmount] = useState(0);
  const [timeToPay, setTimeToPay] = useState('');
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      amount: 0,
    },
    validate: {
      amount: (value) => (value <= 0 ? 'Não pode comprar zero' : null),
    },
    onValuesChange: (value) => setCashAmount(value.amount * 100),
  });

  const buy = (event) => {
    event.preventDefault();
    form.validate();
    if (form.isValid()) {
      let amount = form.values.amount;
      handlePayment(amount);
    }
  };

  const copyPix = () => {
    navigator.clipboard
      .writeText(currentBuyData.point_of_interaction?.transaction_data?.qr_code)
      .then(() => {
        console.log('Texto copiado com sucesso para a área de transferência');
      })
      .catch((error) => {
        console.error(
          'Erro ao copiar texto para a área de transferência:',
          error,
        );
      });
  };

  const calculateTimeLeft = (expiration_date) => {
    const targetDate = new Date(expiration_date);
    const now = new Date();

    const difference = targetDate.getTime() - now.getTime();

    if (difference > 0) {
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      setTimeToPay(`${formattedMinutes}:${formattedSeconds}`);
    }
  };

  useEffect(() => {
    if (currentBuyData) {
      if (currentBuyData.date_of_expiration) {
        const timer = setInterval(() => {
          calculateTimeLeft(currentBuyData.date_of_expiration);
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [currentBuyData]);

  useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    }
  }, [isAuth]);

  return (
    <div className={classes.content}>
      <PageTitle>Comprar cash</PageTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Balance onlyCash />
      </div>
      <PageWrapperFluid>
        {!isBuying && (
          <>
            <div
              className={globalClasses.rowItem}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <p>Ativado em sua conta automaticamente.</p>
              <br />
            </div>
            <form className={classes.form} onSubmit={(e) => buy(e)}>
              <div className={classes.formFlex}>
                <NumberInput
                  label="Digite o valor em real"
                  prefix="R$ "
                  hideControls
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps('amount')}
                  classNames={{
                    wrapper: classes.wrapper,
                    input: classes.input,
                    label: classes.label,
                    description: classes.description,
                    root: classes.root,
                  }}
                />
                <LabelFragment title="Cash" value={cashAmount} />
              </div>
              <Button type="submit" highlight>
                Comprar
              </Button>
            </form>
          </>
        )}
        {isBuying && buyStatus === 'waiting' && (
          <div className={classes.form}>
            {currentBuyData.point_of_interaction?.transaction_data?.qr_code && (
              <QRCodeComponent
                base64Image={
                  currentBuyData.point_of_interaction.transaction_data.qr_code
                }
              />
            )}
            <Button onClick={copyPix}>Copiar chave aleatória</Button>
            <span>Expira em:</span>
            <span className={classes.timer}>{timeToPay}</span>
            <LabelFragment
              title="Comprando..."
              value={`R$ ${currentBuyData.transaction_amount}`}
            />
          </div>
        )}
        {isBuying && buyStatus === 'expired' && (
          <div className={classes.form}>
            <p>Pagamento expirado</p>
            <span>Atualize a página e faça um novo pedido.</span>
          </div>
        )}
        {!isBuying && buyStatus === 'paid' && (
          <div className={classes.form}>
            <LabelFragment
              title="Adquirido"
              value={`${currentBuyData.transaction_amount * 100} CASH`}
            />
            <p>Aperte F7 no jogo para ver o saldo atualizado</p>
          </div>
        )}
      </PageWrapperFluid>
    </div>
  );
};

export default Buy;
