import React, { useContext, useState } from 'react';
import MainInput from '../MainInput';
import classes from './index.module.css';
import { useForm } from '@mantine/form';
import Button from '../../Button/index';
import { AppContext } from '../../../Context/AppContext';
import ReCAPTCHA from 'react-google-recaptcha';

const Signup = () => {
  const { handleSignup } = useContext(AppContext);

  const [captchaValue, setCaptchaValue] = useState('');

  const handleChange = (value) => {
    setCaptchaValue(value);
  };

  const form = useForm({
    initialValues: {
      login: '',
      password: '',
      confirmPassword: '',
      email: '',
      social_id: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email inválido'),
      login: (value) =>
        value.length < 4
          ? 'Login deve conter ao menos 4 dígitos'
          : value.length > 14
          ? 'Login não pode ter mais que 14 dígitos'
          : null,
      social_id: (value) =>
        value.length !== 7
          ? 'Código de apagar personagem deve conter 7 dígitos'
          : null,
      confirmPassword: (value, values) =>
        value !== values.password ? 'Senhas não coincidem' : null,
    },
  });

  const signUp = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      alert('Por favor, resolva o reCAPTCHA.');
      return;
    }
    form.validate();
    if (form.isValid()) {
      handleSignup(form.values);
    }
  };

  return (
    <form onSubmit={(e) => signUp(e)} className={classes.flexBox}>
      <MainInput
        type="text"
        max="14"
        min="4"
        label="login"
        rest={form.getInputProps('login')}
      />
      <MainInput
        type="password"
        label="senha"
        rest={form.getInputProps('password')}
      />
      <MainInput
        type="password"
        label="repita a senha"
        rest={form.getInputProps('confirmPassword')}
      />
      <MainInput
        type="email"
        label="email"
        rest={form.getInputProps('email')}
      />
      <MainInput
        type="text"
        max="7"
        min="7"
        label="apagar personagem"
        rest={form.getInputProps('social_id')}
      />
      <div className={classes.flexBoxCentered}>
        <ReCAPTCHA
          onChange={handleChange}
          sitekey="6LdgKKMpAAAAADLWWpvMMRNc76c00i_fYpf53f3D"
        />
      </div>
      <div className={classes.flexBoxCentered}>
        <Button highlight type="submit">
          Cadastrar
        </Button>
      </div>
    </form>
  );
};

export default Signup;
