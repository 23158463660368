import React from 'react';
import classes from './index.module.css';

const Button = ({ children, highlight, onClick, isSmall, type }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={
        highlight ? classes.highlight : isSmall ? classes.small : classes.root
      }
    >
      {children}
    </button>
  );
};

export default Button;
