import React, { useContext } from 'react';
import classes from './index.module.css';
import masterToken from '../../Assets/token.png';
import { AppContext } from '../../Context/AppContext';
import { Loader, useMantineTheme } from '@mantine/core';

const LabelFragment = ({ isLarge, hasIcon, isHighlight, title, value }) => {
  const { isLoading } = useContext(AppContext);
  const theme = useMantineTheme();
  return (
    <div
      className={`${isLarge ? classes.contentLarge : classes.content} ${
        isHighlight && classes.highlight
      }`}
    >
      <div className={classes.item}>
        <div className={classes.itemLabel}>
          <span>{title}</span>
          {isLoading ? (
            <Loader style={{ paddingTop: 2 }} color={theme.colors.d[0]} />
          ) : (
            <p>{value}</p>
          )}
        </div>
        {hasIcon && <img src={masterToken} alt="Token do Mestre" />}
      </div>
    </div>
  );
};

export default LabelFragment;
