import React, { useContext, useEffect } from 'react';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import PageTitle from '../../Components/PageTitle';
import Button from '../../Components/Button';
import BR from '../../Assets/br.png';
import globalClasses from '../../App.module.css';
import Signup from '../../Components/Forms/Signup';
import { AppContext } from '../../Context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../Components/PageWrapper';

const Register = () => {
  const { isAuth } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) navigate('/conta');
  }, [isAuth]);

  return (
    <div>
      <PageTitle>Cadastro</PageTitle>
      <div
        style={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PageWrapper>
          <h2>Crie sua conta gratuita</h2>
          <Signup />
        </PageWrapper>
      </div>
    </div>
  );
};

export default Register;
