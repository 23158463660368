import { Input, NumberInput, PasswordInput, TextInput } from '@mantine/core';
import React from 'react';
import classes from './index.module.css';

const MainInput = ({ type, max, min, label, rest }) => {
  if (type === 'text') {
    return (
      <TextInput
        className={classes.mainText}
        placeholder={label}
        min={min}
        max={max}
        {...rest}
      />
    );
  } else if (type === 'password') {
    return (
      <PasswordInput
        className={classes.mainPassword}
        placeholder={label}
        {...rest}
      />
    );
  } else if (type === 'email') {
    return (
      <TextInput
        className={classes.mainText}
        placeholder={label}
        type="email"
        {...rest}
      />
    );
  } else if (type === 'number') {
    return <NumberInput label={label} className={classes.mainText} {...rest} />;
  }
};

export default MainInput;
