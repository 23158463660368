import { Loader, Table, useMantineTheme } from '@mantine/core';
import React, { useContext } from 'react';
import classes from './index.module.css';
import { AppContext } from '../../Context/AppContext';

const AppTable = ({ rows, heads }) => {
  const head = heads.map((element) => (
    <Table.Th key={element}>{element}</Table.Th>
  ));
  const { isLoading } = useContext(AppContext);
  const theme = useMantineTheme();

  return isLoading || rows.length === 0 ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
      }}
    >
      <Loader color={theme.colors.d[0]} size="xl" />
    </div>
  ) : (
    <Table className={classes.mainTable}>
      <Table.Thead>
        <Table.Tr>{head}</Table.Tr>
      </Table.Thead>

      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};

export default AppTable;
