import React from 'react';
import classes from './index.module.css';

const PageTitle = ({ children }) => {
  return (
    <div className={classes.titleWrapper}>
      <h1 className={classes.title}>{children}</h1>
    </div>
  );
};

export default PageTitle;
