import React, { useContext, useState } from 'react';
import MainInput from '../MainInput';
import classes from './index.module.css';
import { useForm } from '@mantine/form';
import Button from '../../Button/index';
import { AppContext } from '../../../Context/AppContext';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
  const { handleLogin } = useContext(AppContext);

  const [captchaValue, setCaptchaValue] = useState('');

  const handleChange = (value) => {
    setCaptchaValue(value);
  };

  const form = useForm({
    initialValues: {
      login: '',
      password: '',
    },

    validate: {
      login: (value) =>
        value.length < 4
          ? 'Login deve conter ao menos 4 dígitos'
          : value.length > 14
          ? 'Login não pode ter mais que 14 dígitos'
          : null,
      password: (value) => !value && 'Senha não pode ficar em branco',
    },
  });

  const logIn = (event) => {
    event.preventDefault();
    // if (!captchaValue) {
    //   alert('Por favor, resolva o reCAPTCHA.');
    //   return;
    // }
    form.validate();
    if (form.isValid()) {
      handleLogin(form.values);
    }
  };

  return (
    <form className={classes.flexBox} onSubmit={(e) => logIn(e)}>
      <MainInput
        type="text"
        max="14"
        min="4"
        label="login"
        rest={form.getInputProps('login')}
      />
      <MainInput
        type="password"
        label="senha"
        rest={form.getInputProps('password')}
      />
      <div className={classes.flexBoxCentered}>
        <ReCAPTCHA
          onChange={handleChange}
          sitekey="6LdgKKMpAAAAADLWWpvMMRNc76c00i_fYpf53f3D"
        />
      </div>
      <div className={classes.flexBoxCentered}>
        <Button highlight type="submit">
          Entrar
        </Button>
      </div>
    </form>
  );
};

export default Login;
